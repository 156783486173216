"use strict";
exports.__esModule = true;
exports.extractGroupName = exports.hasPublicEmailDomain = void 0;
var EMAIL_PROVIDERS = [
    'gmail.com',
    'icloud.com',
    'googlemail.com',
    'yahoo.com',
    'live.com',
    'hotmail.com',
    'outlook.com',
    'msn.com',
    'aol.com',
    'comcast.net',
    'sbcglobal.net',
    'att.net',
    'verizon.net',
    'shaw.ca',
    'bell.ca',
    'shawcable.ca',
    'shawcable.net',
    'shaw.net',
    'shaw.com',
    'shawcable.com',
];
var hasPublicEmailDomain = function (email) {
    var domain = email.split('@')[1];
    return EMAIL_PROVIDERS.includes(domain);
};
exports.hasPublicEmailDomain = hasPublicEmailDomain;
var extractGroupName = function (user, fallbackToUserId) {
    if (fallbackToUserId === void 0) { fallbackToUserId = true; }
    if (!user.email)
        return null;
    var domain = user.email.split('@')[1];
    if (EMAIL_PROVIDERS.includes(domain)) {
        return fallbackToUserId ? user.id : null;
    }
    return domain;
};
exports.extractGroupName = extractGroupName;
