import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface HotspotDefaults {
  backgroundColor: string | undefined
  textColor: string | undefined

  setBackgroundColor: (backgroundColor?: string) => Promise<boolean>
  setTextColor: (textColor?: string) => Promise<boolean>

  setBothColors: (
    backgroundColor: string,
    textColor: string
  ) => Promise<boolean>
}

// TODO do something to bake in the global default values here

export const hotspotDefaults: AccountMixin<HotspotDefaults> = (
  core,
  { team }
) => ({
  get backgroundColor() {
    if (team) {
      return getSubfield(team, 'prefs', 'bgColor')
    }
    // TODO get from user profile
  },

  get textColor() {
    if (team) {
      return getSubfield(team, 'prefs', 'fgColor')
    }
    // TODO get from user profile (note: use textColor, not fgColor!)
  },

  setBackgroundColor(backgroundColor: string | undefined) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'bgColor',
        backgroundColor,
        core.userId
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },

  setTextColor(textColor: string | undefined) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'fgColor',
        textColor,
        core.userId
      )
    }
    // TODO set user profile (note: use textColor, not fgColor!)
    return Promise.resolve(false)
  },

  setBothColors(backgroundColor: string, textColor: string) {
    if (team) {
      const prefs = {
        ...(team.prefs ?? {}),
        bgColor: backgroundColor,
        textColor,
      }

      return team.update(
        {
          prefs,
        },
        core.userId
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },
})
