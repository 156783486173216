import { AccountMixin, Plan } from '../AccountCore'

export interface Intercom {
  userHash: string | null
  planDescription: string
}

export const intercom: AccountMixin<Intercom> = (
  _core,
  { userProfile, plan }
) => ({
  get userHash() {
    return userProfile.intercomUserHash ?? null
  },

  get planDescription() {
    // For backwards compatibility with previous Intercom data
    const plans: { [plan in Plan]: string } = {
      Free: 'Free',
      'Free (Growth Churned)': 'Team (churned)',
      'Free (Growth Trial Ended)': 'Team (trial de-provisioned)',
      'Free (Pro Churned)': 'Pro (churned)',
      'Free (Pro Churned) (Growth Churned)': 'Pro (churned)',
      'Free (Pro Churned) (Growth Trial Ended)': 'Pro (churned)',
      Pro: 'Pro',
      'Pro (Growth Churned)': 'Pro',
      'Pro (Growth Trial Ended)': 'Pro',
      Growth: 'Team',
      'Growth Trial': 'Team (trial)',
    }
    return plans[plan]
  },
})
