import { FlowWrapper, Step, StepType } from '@arcadehq/shared/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import useAccount from 'src/auth/useAccount'
import { Flow } from 'src/types'
import { getNewStepFromPartial } from 'src/utils/steps'
import { theme } from 'tailwind.config'

import { DEFAULT_TARGET_ID } from '../../constants'
import { CurrentFlowContext } from './types'

// internal hook. do not use in UI components

export function useFlowProviderContext(
  flow: Flow,
  isReadonly: boolean
): CurrentFlowContext {
  const account = useAccount()

  // Add 'Noto Color Emoji' as fallback for Emojis
  // This will be used during GIF/Video exports
  const fontFamily = useMemo(
    () =>
      `${
        (flow.font || account.flowDefaults.font) ?? theme.extend.fontFamily.sans
      }, "Noto Color Emoji"`,
    [flow.font, account.flowDefaults.font]
  )

  /*
   * Selection
   */

  const [activeStepId, setActiveStepId] = useState<string | null>(null)

  useEffect(() => {
    if (!flow.steps.length || !flow.id) return
    setActiveStepId(flow.steps[0].id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow.id])

  const activeStep = useMemo(() => {
    if (!activeStepId) return null
    return flow.steps.find(step => step.id === activeStepId) ?? null
  }, [flow.steps, activeStepId])

  const activeStepIndex = useMemo(() => {
    if (!activeStepId) return -1
    return flow.steps.findIndex(step => step.id === activeStepId)
  }, [flow.steps, activeStepId])

  /*
   * Modifiers
   */

  const addOrUpdateSteps = useCallback(
    async (
      newOrUpdatedSteps: Step[],
      insertAfterActiveStep = false,
      retainHotspots = false,
      insertAtIndex = -1
    ) => {
      if (!flow) {
        return []
      }

      // First update any existing steps (existing steps will have an id that matches a step in the flow)
      const updatedSteps = flow.steps.map(
        step => newOrUpdatedSteps.find(s => s.id === step.id) || step
      )
      const newSteps = newOrUpdatedSteps.filter(
        step => !flow.steps.find(s => s.id === step.id)
      )

      const insertIdx = insertAfterActiveStep
        ? flow.steps.findIndex(step => step.id === activeStep?.id) + 1
        : insertAtIndex >= 0
        ? insertAtIndex
        : flow.steps.length

      const combinedSteps = [
        ...updatedSteps.slice(0, insertIdx),
        ...newSteps.map(step =>
          getNewStepFromPartial({
            ...step,
            hotspots: retainHotspots ? step.hotspots : [],
          })
        ),
        ...flow.steps.slice(insertIdx, flow.steps.length),
      ].filter(step => step)
      await flow.update({ steps: combinedSteps }, account.userId)
      return combinedSteps
    },
    [account.userId, activeStep?.id, flow]
  )

  const updateActiveStep = useCallback(
    async (properties: Partial<Step>) => {
      if (!activeStep) {
        return
      }
      if (activeStep.targetId === DEFAULT_TARGET_ID) {
        delete activeStep.targetId
      }

      const updatedStep = {
        ...activeStep,
        ...properties,
      }

      // remove empty properties
      Object.keys(updatedStep).forEach(key => {
        if (updatedStep[key as keyof Step] === undefined) {
          delete updatedStep[key as keyof Step]
        }
      })

      const updatedSteps = flow.steps.map(el =>
        el.id === updatedStep.id ? updatedStep : el
      )
      await flow.update({ steps: updatedSteps }, account.userId)
    },
    [activeStep, flow, account.userId]
  )

  const deleteSteps = useCallback(
    async (stepsToDelete: Step[]) => {
      if (!flow) return
      toast.success(
        `${stepsToDelete.length} ${
          stepsToDelete.length > 1
            ? 'Steps'
            : stepsToDelete[0].type === StepType.Overlay
            ? 'Overlay'
            : 'Step'
        } deleted`
      )
      let currSteps = flow.steps
      let idxOfFirstItemDeleted = -1
      stepsToDelete.forEach((stepToDelete, idx) => {
        // Find index of step to delete
        const idxOfStepToDelete = currSteps.findIndex(
          s => s.id === stepToDelete.id
        ) as number
        if (idx === 0) {
          idxOfFirstItemDeleted = idxOfStepToDelete
        }

        // Determine next logical step for hotspots to point to
        if (idxOfStepToDelete === -1) return
        const idxOfNextStep =
          idxOfStepToDelete === (currSteps.length || 0) - 1
            ? 0
            : idxOfStepToDelete + 1
        const nextStep = currSteps[idxOfNextStep]

        // reassign hotspots pointing to the step to be deleted
        currSteps = currSteps
          .filter(el => el.id !== stepToDelete.id)
          .map(
            step =>
              ({
                ...step,
                hotspots: step.hotspots.map(hs => ({
                  ...hs,
                  targetId:
                    hs.targetId === stepToDelete.id && nextStep
                      ? nextStep.id
                      : hs.targetId,
                })),
              } as Step)
          )

        currSteps.forEach(step => {
          if (step.targetId === DEFAULT_TARGET_ID) {
            delete step.targetId
          }

          step.hotspots.forEach(hotspot => {
            if (hotspot.targetId === DEFAULT_TARGET_ID) {
              delete hotspot.targetId
            }
          })
        })
      })
      if (
        stepsToDelete.map(step => step.id).includes(activeStep?.id || '') &&
        currSteps.length > 0
      ) {
        const newActiveStepIndex = Math.max(0, idxOfFirstItemDeleted - 1)
        setActiveStepId(currSteps[newActiveStepIndex].id)
      }

      await flow.update({ steps: currSteps }, account.userId)
    },
    [activeStep?.id, flow, account.userId]
  )

  return {
    flow,
    isReadonly,
    hasBackground: !!flow.bgImage?.url,
    hasWrapper: flow.flowWrapper !== FlowWrapper.none,
    hasOverlay: flow.showStartOverlay,
    fontFamily,

    //selection
    activeStep,
    activeStepIndex,
    setActiveStep: setActiveStepId,

    //modifiers
    addOrUpdateSteps,
    updateActiveStep,
    deleteSteps,
  }
}
