import { setUser as setSentryUser } from '@sentry/nextjs'
import { createContext, PropsWithChildren, useEffect } from 'react'
import { Account } from 'src/auth/Account'
import defaultAccount from 'src/auth/defaultAccount'
import { Team, User } from 'src/types'
import { passUserToExtension } from 'src/utils/extension'

// see src/auth/withAuth.tsx for details
// the actual user is loaded in `withAuth`
export const defaultUser: User = {
  id: null,
  isLoggedIn: false,
  name: null,
  email: null,
  emailVerified: false,
  emailDomain: null,
  photoURL: null,
  getIdToken: async () => null,
  clientInitialized: false,
  firebaseUser: null,
  signOut: async () => {},
  group: null,
  intercomUserId: null,
  intercomUserHash: null,
  prefs: null,
  features: null,
  currentSubscriber: false,
  customerId: null,
  subscriptionDate: null,
  activeTeamId: null,
  isActiveMemberOfTeam: false,
  isExternalMemberOfTeam: false,
  isTeamAdmin: false,
  hasActiveInvitationToTeam: false,
  onboardingUseCases: {
    Marketing: false,
    Product: false,
    Sales: false,
    CustomerSuccess: false,
    Other: null,
  },
  onboardingCompanySize: null,
  getStartedArticlesHidden: null,
  dismissedGrowthTrialBanner: false,
  isOverdue: false,
  overdueDate: null,
}

export const AuthContext = createContext<{
  user: User
  team: Team | null
  account: Account
  algoliaApiKey?: string
}>({
  user: defaultUser,
  team: null,
  account: defaultAccount,
})

export function AuthProvider({
  user,
  team,
  account = defaultAccount,
  algoliaApiKey,
  children,
}: PropsWithChildren<{
  user?: User
  team?: Team | null
  account?: Account
  algoliaApiKey?: string
}>) {
  useEffect(() => {
    if (account.userId) {
      setSentryUser({ id: account.userId })
      passUserToExtension({
        id: account.userId,
        email: account.userEmail,
        displayName: account.userName,
      })
      // hotjar
      ;(window as any).hj?.('identify', account.userId, {})
    }
  }, [account.userEmail, account.userId, account.userName])

  const value = {
    user: user ?? defaultUser,
    team: team ?? null,
    account,
    ...(algoliaApiKey ? { algoliaApiKey } : {}),
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
