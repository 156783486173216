import 'src/polyfills'
import 'styles/global.css'

import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { RecoilEnv, RecoilRoot } from 'recoil'
import { AuthProvider } from 'src/auth/AuthContext'
import { FlowProvider } from 'src/flow/FlowContext'
import { useFlowProviderContext } from 'src/flow/useFlowProviderContext'
import { isProductionEnv } from 'src/helpers'
import { getEmptyFlow } from 'src/utils/flows'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false

function MyApp({ Component, pageProps }: AppProps) {
  const props: {
    [key: string]: any
    disableNProgress?: boolean
    disableGoogleTagManager?: boolean
  } = {
    ...pageProps,
  }
  useEffect(() => {
    if (props.disableNProgress) return
    NProgress.configure({ showSpinner: false, easing: 'ease', speed: 500 })
    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())
  }, [props.disableNProgress])

  const flowContextProps = useFlowProviderContext(getEmptyFlow(), false)

  return (
    <RecoilRoot>
      <AuthProvider>
        <FlowProvider contextProps={flowContextProps}>
          <Head>
            <meta charSet='utf-8' />
            <meta
              name='viewport'
              content='initial-scale=1.0, width=device-width'
            />
            <link
              rel='shortcut icon'
              type='image/png'
              href='https://cdn.arcade.software/images/button-logo-128.png'
            />
            <link
              rel='apple-touch-icon'
              href='https://cdn.arcade.software/images/button-logo-128.png'
            />
            <meta
              name='msapplication-TileImage'
              content='https://cdn.arcade.software/images/button-logo-128.png'
            ></meta>
          </Head>
          {isProductionEnv() && !props.disableGoogleTagManager ? (
            <Script
              id='google-tag-manager'
              strategy='afterInteractive'
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KQ36BV64');`,
              }}
            />
          ) : null}
          <Component {...pageProps} />
        </FlowProvider>
      </AuthProvider>
    </RecoilRoot>
  )
}

export default MyApp
