import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface SharePage {
  logoLinkUrl: string | undefined
  logoImageUrl: string | undefined
  ctaButtonText: string | undefined
  ctaButtonColor: string | undefined
  ctaButtonTextColor: string | undefined
  ctaButtonLinkUrl: string | undefined

  setLogoLinkUrl: (logoLinkUrl?: string) => Promise<boolean>
  setLogoImageUrl: (logoImageUrl?: string) => Promise<boolean>
  setCtaButtonText: (ctaButtonText?: string) => Promise<boolean>
  setCtaButtonColor: (ctaButtonColor?: string) => Promise<boolean>
  setCtaButtonTextColor: (ctaButtonTextColor?: string) => Promise<boolean>
  setCtaButtonLinkUrl: (ctaButtonLinkUrl?: string) => Promise<boolean>
}

export const sharePage: AccountMixin<SharePage> = (core, { team }) => ({
  get logoLinkUrl() {
    return getSubfield(team, 'sharePageLogo', 'link')
  },

  get logoImageUrl() {
    return getSubfield(team, 'sharePageLogo', 'imageUrl')
  },

  get ctaButtonText() {
    return getSubfield(team, 'sharePageButton', 'text')
  },

  get ctaButtonColor() {
    return getSubfield(team, 'sharePageButton', 'bgColor')
  },

  get ctaButtonTextColor() {
    return getSubfield(team, 'sharePageButton', 'textColor')
  },

  get ctaButtonLinkUrl() {
    return getSubfield(team, 'sharePageButton', 'link')
  },

  setLogoLinkUrl(logoLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageLogo',
      'link',
      logoLinkUrl,
      core.userId
    )
  },

  setLogoImageUrl(logoImageUrl: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageLogo',
      'imageUrl',
      logoImageUrl,
      core.userId
    )
  },

  setCtaButtonText(ctaButtonText: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageButton',
      'text',
      ctaButtonText,
      core.userId
    )
  },

  setCtaButtonColor(ctaButtonColor: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageButton',
      'bgColor',
      ctaButtonColor,
      core.userId
    )
  },

  setCtaButtonTextColor(ctaButtonTextColor: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageButton',
      'textColor',
      ctaButtonTextColor,
      core.userId
    )
  },

  setCtaButtonLinkUrl(ctaButtonLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      team,
      'sharePageButton',
      'link',
      ctaButtonLinkUrl,
      core.userId
    )
  },
})
